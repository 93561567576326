<template>
    <div class="product-box">
        <div class="product-center">
            <!-- <SubTitle :title="title" :enTitle="enTitle"></SubTitle> -->
            <SubDesc title="玩具行业" titleDesc="玩具厂商面临创造力匮乏、信息流通不畅、决策依据不足的挑战，我们专为此打造了BI产品与亲缘智伴（KIN AI）"></SubDesc>
            <div class="product-list">
                <div class="product-item" v-for="(item,idx) in productList" :key="idx" :id="item.elementId">
                    <div class="product-img">
                        <img :src="item.imgSrc">
                    </div>
                    <div class="product-info">
                        <SecondTitle :secTitle="item.name"></SecondTitle>
                        <div class="desc" v-html="item.desc"></div>
                        <div class="opt" v-if="item.type == 1">
                            
                            <a class="btn cursor" href="http://bi.sssrise.com/" target="_blank">立即体验</a>
                        </div>
                        <div class="opt" v-if="item.type == 2">
                            <el-popover class="code-item" placement="top" width="280" trigger="click">
                                <div class="">
                                    <img src="@/assets/images/about/wechat_3.jpg">
                                </div>
                                <div slot="reference" class="btn cursor">立即体验</div>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
            <SubDesc title="黄金行业" titleDesc="传统黄金行业线上线下融合不足、技术创新应用不足。我们专为此打造了数字化商城和AI私人定制助手"></SubDesc>
            <div class="product-list">
                <div class="product-item" v-for="(item,idx) in goldList" :key="idx">
                    <div class="product-img">
                        <img :src="item.imgSrc">
                    </div>
                    <div class="product-info">
                        <SecondTitle :secTitle="item.name"></SecondTitle>
                        <div class="desc" v-html="item.desc"></div>
                        <!-- <div class="opt">
                            <div class="btn">立即体验</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="top-header">
                <SubTitle title="未来我们将开展更多的行业"></SubTitle>
                <div class="btn cursor" @click="goAbout">其他行业前去定制</div>
            </div>
        </div>

    </div>
</template>
<script>
    import SubTitle from "../subTitle/index.vue";
    import SecondTitle from "@/components/secondTitle/index.vue";
    import SubDesc from "@/components/subTitle/subDesc.vue";
    export default {
        components: {
            SubTitle,
            SecondTitle,
            SubDesc
        },
        data() {
            return {
                title: "BI产品",
                enTitle: "BI products",
                productList: [{
                    name: "BI产品",
                    desc: "专为玩具行业打造，涵盖玩具市场分析、IP分析与产品策略三大核心板块。通过全面的销量与销售额分析、热门品类深度解析、多维度IP洞察，以及先进的大数据算法，我们为您提供全面的市场洞察与大数据决策支持。无论是精准定位市场、制定有效营销策略，还是抢占市场先机、制定高效产品策略，我们的BI产品都能助您一臂之力，实现玩具业务的持续增长与创新发展。",
                    imgSrc: require("@/assets/images/common/wjsc.png"),
                    elementId: "report",
                    type: 1
                }, {
                    name: "亲缘智伴（KIN AI）",
                    desc: "我们构建了一个以用户需求为核心，实现生产者、消费者与创意者无缝连接、协同共创的产品生态系统。该产品为各位的宝贝量身打造全方位、个性化的育儿指导方案，借助AI的智慧力量，不仅为玩具赋予新的指导和玩法，助力各位家长科学育儿，轻松应对各种育儿挑战！同时挖掘用户的痛点和需求点，使玩具厂商能够更准确地理解并满足核心用户的需求，让爱与智慧同行，陪伴宝贝健康成长！",
                    imgSrc: require("@/assets/images/common/zsai.png"),
                    elementId: "strategy",
                    type: 2
                }],
                goldList: [{
                    name: "数字化商城",
                    desc: "我们为黄金行业精心打造了全面的数字化解决方案，旨在创新构建一个黄金商场平台，该平台致力于彻底打破黄金投资与工业用途之间的传统界限，实现两者的高效融合与互补。通过这一平台，我们不仅为投资者提供了丰富多样的增值服务，还积极与非遗工艺师展开合作，借助传统古法工艺来弘扬中国传统文化，赋予黄金更深厚的文化内涵与价值。",
                    imgSrc: require("@/assets/images/common/szh.png"),
                }, {
                    name: "AI私人定制助手",
                    desc: "探索全新黄金定制时代，我们正引领一场前所未有的变革！借助先进的AI技术，我们为您精心打造独一无二的私人定制黄金体验。无论是追求精美的饰品，还是寻求个性化的投资佳品，我们都能满足您的独特品味与需求。让AI设计成为潮流的引领者，为您的黄金选择开启无限可能，让每一款黄金作品都闪耀着独特的光芒，彰显您的非凡品味与独特个性！",
                    imgSrc: require("@/assets/images/common/ai_service.png"),
                }]
            }
        },
        methods: {
            goAbout() {
                this.$router.push({
                    path: "About"
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    .product-box {
        width: 100%;
        margin-top: 60px;
        a {
            text-decoration: none;
        }
        .product-center {
            width: 1200px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;

            .top-header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 50px 0;

                .btn {
                    width: 198px;
                    height: 56px;
                    background: #3672FD;
                    border-radius: 10px;
                    font-weight: bold;
                    font-size: 20px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .product-list {
                display: flex;
                flex-direction: column;
                margin-top: 50px;

                .product-item {
                    display: flex;
                    margin-bottom: 80px;
                    cursor: pointer;

                    .product-img {
                        img {
                            width: 600px;
                            height: 340px;
                            border-radius: 10px;
                        }
                    }

                    .product-info {
                        display: flex;
                        flex-direction: column;
                        padding-left: 30px;

                        .desc {
                            font-weight: 400;
                            font-size: 14px;
                            color: #626A83;
                            line-height: 20px;
                            text-align: justify;
                            font-style: normal;
                            margin-top: 24px;
                            padding-left: 16px;
                            flex: 1;
                        }

                        .opt {
                            display: flex;

                            /* justify-content: flex-end; */
                            .btn {
                                width: 150px;
                                height: 40px;
                                background: #3672FD;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: bold;
                                font-size: 17px;
                                color: #FFFFFF;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>